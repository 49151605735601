<template>
  <div class="vip-wapper">
    <div class="right">
      <img src="@/assets/images/vip-right.png" alt="" />
    </div>
    <div class="left">
      <img src="@/assets/images/vip-right.png" alt="" />
    </div>
    <swiper :options="swiperOptionThumbs" class="swiper-vip" ref="swiper" v-if="initList.length">
      <swiper-slide v-for="(item, index) in initList" :key="index">
        <div class="vip-item" @click="toVip" :class="{'vip-item-active':item.id === $store.state.userInfo?.level_id}">
          <img :src="item.levelImg" class="level-img" />
          <div class="title">{{ $t(item.level_name) }}</div>
          <div class="item-detail" v-if="index < initList.length - 1">
            <div class="item-detail-item">
              <span :title="$t('等级彩金')">{{ $t("等级彩金") }}</span>
              <span> {{ $helper.get("rule").currency.symbol }}{{ numFormat(item.level_gold, true, 0) }}</span>
            </div>
            <div class="item-detail-item" v-for="vip_condition in item.vip_condition" :key="vip_condition.key">
              <span :title="$t(vip_condition.key)">{{ $t(vip_condition.key) }}</span>
              <span>{{ vip_condition.value }}</span>
            </div>
            <div class="item-detail-item item-detail-item-close" v-if="!item.vip_condition.length">
              <span :title="$t('累计充值')">{{ $t("累计充值") }}</span>
              <span class="el-icon-close el-icon"></span>
            </div>
            <div class="item-detail-item item-detail-item-close" v-if="!item.vip_condition.length">
              <span :title="$t('游戏流水')">{{ $t("游戏流水") }}</span>
              <span class="el-icon-close el-icon"></span>
            </div>
          </div>
          <div class="item-detail" v-else>
            <div class="item-detail-item-last" >
              <span>{{ $t("升级条件投注金额") }}</span>
              <span>{{ $t("邀请") }}</span>
            </div>
            <div class="item-detail-item-last" >
              <span>{{ $t("升级条件存款金额") }}</span>
              <span>{{ $t("邀请") }}</span>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
export default {
  data() {
    return {
      swiperOptionThumbs: {
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".right",
          prevEl: ".left",
        },
        slidesPerView: 3,
        spaceBetween: 16,
        breakpoints: {
          540: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 2,
          },
        },
      },
    };
  },
  props: {
    list: {
      default: [],
    },
    cashbackList: {
      default: [],
    },
  },
  computed: {
    /**
     * 格式化list列表,添加现金返还数据,取最大数据显示
     * */
    initList() {
      let list = JSON.parse(JSON.stringify(this.list));
      list.forEach((item) => {
        this.cashbackList.forEach((cashbackListItem) => {
          cashbackListItem.vipwater.forEach((vipwaterItem) => {
            if (item.level_name == vipwaterItem.level_name) {
              if (item.cashback) {
                if (item.cashback < vipwaterItem.gbc_rate) {
                  item.cashback = vipwaterItem.gbc_rate;
                }
              } else {
                item.cashback = vipwaterItem.gbc_rate;
              }
            }
          });
        });
      });
      return list;
    },
  },
  watch: {
    initList: {
      immediate: true,
      handler() {
        setTimeout(() => {
          const page = this.initList.findIndex(item => item.id === this.$store.state.userInfo.level_id)
          this.$refs?.swiper?.swiper.slideTo(page);
        }, 50)
      },

    }
  },
  methods: {
    toVip() {
      this.$router.push("/rank-system")
    }
  }
};
</script>

<style scoped lang="less" src="@/assets/css/vipList.less"></style>
